body::before {
  content: 'breakpoint-mobile';
  position: absolute;
  opacity: 0;

  @include breakpoint($breakpoint-narrow) {
    content: 'breakpoint-narrow';
  }

  @include breakpoint($breakpoint-wide) {
    content: 'breakpoint-wide';
  }

  @include breakpoint($breakpoint-landscape-extrem) {
    content: 'breakpoint-landscape-extrem';
  }
}

body::after {
  content: 'no-touch-device';
  height: 0;
  position: absolute;
  opacity: 0;
  top: 0;
  width: 0;

  @media (pointer:coarse) {
    content: 'touch-device';
  }
}
