.webform-client-form {
  width: 100%;
}

.webform-component {
  label {
    display: block;
  }

  .form-type-radio {
    label {
      display: inline;
    }
  }
}
