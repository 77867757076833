$nav-background: rgba( $color-black, 0.33);
$nav-active-background: rgba( $color-black, 0.66);

.header__nav {
  width: 100%;
  z-index: 20;

  .menu {
    list-style: none;
  }

  @include breakpoint(max-width $breakpoint-narrow - 1) {

    .block-menu {
      background: $color-grey;
      display: none;
      margin: 0 0 - $page-gutter / 2;
    }

    .menu {
      margin: 0;
    }

    .menu__item {
      margin: 0;
    }

    .menu__link {
      border-bottom: 1px solid $color-white;
      color: $color-white;
      display: block;
      padding: $page-gutter / 2;
    }

    .menu .menu {
      background: lighten($color-grey, 10%);
      border-bottom: 1px solid $color-white;
    }

    .nav-main__icon--open + .header__region .block-menu {
      display: block;
    }

    .nav-main__icon {
      text-align: right;

      &::after {
        background-image: url('../images/icon--menu.svg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        display: inline-block;
        content: '';
        height: 2.5em;
        width: 2.5em;
      }
    }
  }

  @include breakpoint($breakpoint-narrow) {
    .menu {
      display: flex;
      justify-content: space-between;
      margin: 0;
      padding: 0;
      position: relative;
    }

    .menu__item {
      border-right: 0.1em solid transparent;
      border-bottom: 0.1em solid transparent;
      flex-grow: 1;
      margin: 0;

      &:last-child {
        border-right: 0;
      }
    }

    .section-wohnungssuche & {
      .menu__item.wohnungssuche .menu {
        display: block;
      }

      .menu:hover .menu__item.wohnungssuche .menu {
        display: none;
      }

      .menu:hover .menu__item.wohnungssuche:hover .menu {
        display: block;
      }
    }

    .menu__link {
      background: $nav-background;
      color: $color-white;
      display: block;
      padding: 0.25em 1em;
      text-align: center;
      text-transform: uppercase;

      &.active,
      &.is-active-trail,
      &:hover {
        background:  $nav-active-background;
        text-decoration: none;
      }

      &::after {
        content: ' »';
        padding-left: 0;
      }
    }



    .menu .menu {
      background: $nav-active-background;
      column-count: 4;
      display: none;
      left: 0;
      padding: $page-gutter;
      position: absolute;
      top: 100%;
      width: 100%;

      .menu__item {
        border: 0;
      }

      .menu__link {
        background: transparent;
        text-align: left;
        text-transform: none;
        padding: 0;

        &.active,
        &:hover {
          color: $color-red;
          text-decoration: none;
        }

        &::after {
          display: none;
        }
      }
    }

    .menu__item:hover .menu {
      display: block;
    }
  }
}

.touch-device {
  .first-tap + .menu {
    display: none;
  }

  .menu__link:not(.first-tap) + .menu {
    display: block;
  }
}
