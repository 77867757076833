* {
  box-sizing: border-box;
}

img {
  background: transparent;
  background-color: transparent;
  max-width: 100%;
  height: auto;
}

p {
  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

a {
  color: $font-color;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

ul {
  list-style: none;
  margin: 1em 0;
  padding: 0;
  text-align: left;

  li {
    margin: 0.5em 0;
  }
}

strong {
  font-weight: 700;
}
