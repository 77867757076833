.view-mode-teaser {
  background: $color-grey-light;
  border: 0.1em solid $color-grey;
  padding: $page-gutter / 2;
  width: 100%;

  @include breakpoint($breakpoint-narrow) {
    padding: $page-gutter;
  }

  h2 {
    line-height: 1.25;
    margin-bottom: 0.25em;
  }

  .field-name-body {
    
    @include breakpoint($breakpoint-narrow) {
      font-size: px2em(14);
    }
  }

  .more-link {
    display: block;
    text-align: right;

    &:after {
      content: ' »';
    }
  }

  .label-inline {
    font-weight: 400;
  }
}
