@charset "UTF-8";
@font-face {
  font-family: 'FontAwesome';
  src: url("../bower_components/font-awesome/fonts/fontawesome-webfont.eot?v=public/themes/base/bower_components/font-awesome/fonts");
  src: url("../bower_components/font-awesome/fonts/fontawesome-webfont.eot?#iefix&v=4.0") format("embedded-opentype"), url("../bower_components/font-awesome/fonts/fontawesome-webfont.woff2?v=4.0") format("woff2"), url("../bower_components/font-awesome/fonts/fontawesome-webfont.woff?v=4.0") format("woff"), url("../bower_components/font-awesome/fonts/fontawesome-webfont.ttf?v=4.0") format("truetype"), url("../bower_components/font-awesome/fonts/fontawesome-webfont.svg?v=4.0#fontawesomeregular") format("svg");
  font-weight: normal;
  font-style: normal; }

.form-item {
  margin: 1em 0; }

legend {
  font-weight: bold;
  text-transform: uppercase; }

fieldset {
  border: 1px solid #F3F3F3;
  padding: 0.75em; }
  @media (min-width: 768px) {
    fieldset {
      padding: 1.5em; } }

textarea,
input {
  border: 1px solid #F3F3F3;
  font-size: 1em;
  padding: 0.25em; }

input[type="text"],
input[type="email"],
textarea {
  display: block;
  width: 75%; }
  input[type="text"]:focus,
  input[type="email"]:focus,
  textarea:focus {
    outline: none; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    color: #1a1a1a; }
  input[type="text"]::-moz-placeholder,
  input[type="email"]::-moz-placeholder,
  textarea::-moz-placeholder {
    color: #1a1a1a; }
  input[type="text"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    color: #1a1a1a; }
  input[type="text"]:-moz-placeholder,
  input[type="email"]:-moz-placeholder,
  textarea:-moz-placeholder {
    color: #1a1a1a;
    font-size: 1.25em; }

input[type="submit"],
.buttons a {
  color: #fff;
  cursor: pointer;
  background-color: #e30613;
  border: 0;
  display: inline-block;
  padding: 0.25em 2em;
  margin: 0 0.5em;
  text-decoration: none;
  transition: background-color 0.5s linear; }
  input[type="submit"]:first-child,
  .buttons a:first-child {
    margin-left: 0; }
  input[type="submit"]:last-child,
  .buttons a:last-child {
    margin-right: 0; }
  input[type="submit"]:hover,
  .buttons a:hover {
    background-color: #b1050f;
    text-decoration: none; }

label {
  display: block; }

.label-inline {
  display: inline-block;
  font-weight: bold; }

* {
  box-sizing: border-box; }

img {
  background: transparent;
  background-color: transparent;
  max-width: 100%;
  height: auto; }

p:first-child {
  margin-top: 0; }

p:last-child {
  margin-bottom: 0; }

a {
  color: #1a1a1a;
  text-decoration: none; }
  a:hover {
    text-decoration: underline; }

ul {
  list-style: none;
  margin: 1em 0;
  padding: 0;
  text-align: left; }
  ul li {
    margin: 0.5em 0; }

strong {
  font-weight: 700; }

body {
  margin: 0;
  padding: 0; }

.region-hidden {
  display: none; }

.page {
  padding: 0 0.75em; }
  @media (min-width: 768px) {
    .page {
      padding: 0 1.5em; } }

html {
  font-weight: 300;
  font-size: 0.875em; }
  @media (min-width: 768px) {
    html {
      font-size: 1em; } }

body {
  color: #1a1a1a;
  font-family: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans";
  line-height: 1.5;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1 {
  font-weight: bold;
  font-size: 1.5em;
  line-height: 1.2;
  margin: 1em 0;
  text-transform: uppercase; }
  @media (min-width: 768px) {
    h1 {
      font-size: 1.5em; } }
  h1:first-child {
    margin-top: 0; }

h2 {
  font-weight: bold;
  font-size: 1.5em;
  text-transform: uppercase; }
  @media (min-width: 768px) {
    h2 {
      font-size: 1.5em; } }
  h2:first-child {
    margin-top: 0; }

h3 {
  font-weight: 300;
  font-size: 1em; }
  @media (min-width: 768px) {
    h3 {
      font-size: 1em; } }
  h3:first-child {
    margin-top: 0; }

p {
  font-size: 1em; }

body::before {
  content: 'breakpoint-mobile';
  position: absolute;
  opacity: 0; }
  @media (min-width: 768px) {
    body::before {
      content: 'breakpoint-narrow'; } }
  @media (min-width: 1024px) {
    body::before {
      content: 'breakpoint-wide'; } }
  @media (orientation: landscape) and (min-aspect-ratio: 2 / 1) {
    body::before {
      content: 'breakpoint-landscape-extrem'; } }

body::after {
  content: 'no-touch-device';
  height: 0;
  position: absolute;
  opacity: 0;
  top: 0;
  width: 0; }
  @media (pointer: coarse) {
    body::after {
      content: 'touch-device'; } }

.compass {
  display: flex;
  justify-content: center; }
  .compass__image {
    background-image: url("../images/wgab-kompass-nord.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 3em;
    width: 3em; }
    .compass__image--nno {
      transform: rotate(22.5deg); }
    .compass__image--no {
      transform: rotate(45deg); }
    .compass__image--ono {
      transform: rotate(67.5deg); }
    .compass__image--o {
      transform: rotate(90deg); }
    .compass__image--oso {
      transform: rotate(112.5deg); }
    .compass__image--so {
      transform: rotate(135deg); }
    .compass__image--sso {
      transform: rotate(157.5deg); }
    .compass__image--s {
      transform: rotate(180deg); }
    .compass__image--ssw {
      transform: rotate(202.5deg); }
    .compass__image--sw {
      transform: rotate(225deg); }
    .compass__image--wsw {
      transform: rotate(247.5deg); }
    .compass__image--w {
      transform: rotate(270deg); }
    .compass__image--wnw {
      transform: rotate(292.5deg); }
    .compass__image--nw {
      transform: rotate(315deg); }
    .compass__image--mmw {
      transform: rotate(337.5deg); }

.content ul {
  list-style: disc;
  padding-left: 1.2rem; }

.content ol {
  list-style: decimal;
  padding-left: 1.2rem; }

.content__image {
  margin: 0 -0.75em; }
  @media (min-width: 768px) {
    .content__image {
      margin: 0 -1.5em; } }
  .content__image img {
    max-width: none;
    width: 100%; }

.content__inner {
  margin: 1.5em auto 0;
  max-width: 1200px; }
  @media (min-width: 768px) {
    .content__inner {
      margin: 2rem auto 0; } }
  .node-wohnung .content__inner {
    display: flex;
    flex-wrap: wrap; }

@media (min-width: 768px) {
  .content__left {
    padding-right: 0.75em;
    width: 60%; } }

@media (min-width: 768px) {
  .content__right {
    padding-left: 0.75em;
    width: 40%; } }

.content-bottom {
  margin: 1.5em 0 0;
  position: relative; }
  @media (min-width: 768px) {
    .content-bottom {
      margin: 3em 0 0; } }
  .content-bottom__inner {
    margin: 1.5em auto 0;
    max-width: 1200px; }
    @media (min-width: 768px) {
      .content-bottom__inner {
        margin: 3em auto 0; } }
  .content-bottom:before {
    background: #e30613;
    content: '';
    display: block;
    padding-top: 10%;
    margin: 0 -0.75em; }
    @media (min-width: 768px) {
      .content-bottom:before {
        margin: 0 -1.5em; } }

.field-name-field-bilder-inhalt {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.5em; }
  .field-name-field-bilder-inhalt .field-item {
    line-height: 0;
    padding: 0.5em;
    width: 20%; }

.footer {
  background: #808080;
  color: #fff;
  margin: 1.5em -0.75em 0;
  padding: 0.75em; }
  @media (min-width: 768px) {
    .footer {
      margin: 3em -1.5em 0;
      padding: 1.5em; } }
  .footer a {
    color: #fff; }
  .footer__inner {
    margin: 0 auto;
    max-width: 1200px; }
  .footer .region-footer {
    margin: 0 -0.75em; }
    @media (min-width: 768px) {
      .footer .region-footer {
        align-items: flex-end;
        display: flex;
        margin: 0 -1.5em; } }
  .footer .block {
    margin: 0 0.75em 2em; }
    @media (min-width: 768px) {
      .footer .block {
        margin: 0 1.5em;
        width: 50%; } }
    .footer .block:last-child {
      margin-bottom: 0; }

.gallery img {
  line-height: 0; }

.gallery__pager {
  display: flex;
  flex-wrap: wrap;
  margin: 1.5em -0.2em; }

.gallery__pager-item {
  border: 0.2em solid #fff;
  cursor: pointer;
  line-height: 0;
  width: 25%; }

.gallery .cycle-pager-active {
  border: 0.2em solid #e30613; }

.gallery .field-item {
  line-height: 0; }

.header {
  margin: 0 auto;
  max-width: 1200px;
  position: relative;
  z-index: 200; }
  @media (min-width: 768px) {
    .header__nav,
    .header .nav-filter {
      left: 0;
      position: absolute;
      top: 100%; } }
  .header__inner {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    padding: 1.5em 0; }
    @media (min-width: 768px) {
      .header__inner {
        flex-wrap: wrap;
        padding: 0 0 1.5em; } }
  .header__logo {
    width: 30%; }
    @media (min-width: 768px) {
      .header__logo {
        width: 15%; } }
    .header__logo img {
      height: 100%;
      width: 100%;
      max-width: 7.5rem; }
  .header__slogan {
    padding-left: 1.5em;
    width: 70%;
    text-align: right; }
    @media (min-width: 768px) {
      .header__slogan {
        width: 66.66667%; } }
    .header__slogan img {
      height: 100%;
      width: 100%;
      max-width: 40rem; }

.messages {
  padding: 0.5em 1em;
  background-color: #006400;
  color: #fff;
  margin-top: 1.5em;
  width: 100%; }
  .messages a {
    color: #fff;
    text-decoration: underline; }
  @media (min-width: 768px) {
    .messages {
      padding: 1em 2em; } }
  .messages.warning {
    background-color: #b8860b; }
  .messages.error {
    background-color: #9d1309; }
  .messages ul {
    list-style: none;
    max-width: 1200px;
    margin: 0 auto; }
    .messages ul li {
      padding: 0.25em 0; }

.nav-filter {
  padding: 0.75em;
  width: 100%;
  z-index: 10; }
  @media (min-width: 768px) {
    .nav-filter {
      column-count: auto !important;
      display: none;
      padding: 0; } }
  .section-wohnungssuche .nav-filter {
    display: block; }
  .nav-filter .region {
    color: #fff;
    width: 100%; }
    @media (min-width: 768px) {
      .nav-filter .region {
        display: flex;
        margin: 0 -1.5em; } }
  .nav-filter .block {
    flex-grow: 1;
    padding: 0.75em 0; }
    @media (min-width: 768px) {
      .nav-filter .block {
        padding: 0 1.5em; } }
  .nav-filter h2 {
    font-size: 1em;
    margin: 0; }
    @media (min-width: 768px) {
      .nav-filter h2 {
        margin: 0 0 1em; } }
  .nav-filter a {
    color: #fff; }
    .nav-filter a:hover {
      color: #D8232A;
      text-decoration: none; }
  .nav-filter ul {
    margin: 0;
    padding: 0; }
  .nav-filter .leaf {
    margin: 0;
    padding: 0; }

.footer .menu {
  display: flex;
  margin: 0;
  padding: 0; }
  .footer .menu .menu__item {
    margin: 0; }
    .footer .menu .menu__item::after {
      content: '|';
      margin: 0 0.5em; }
    .footer .menu .menu__item:last-child::after {
      display: none; }

.header__nav {
  width: 100%;
  z-index: 20; }
  .header__nav .menu {
    list-style: none; }
  @media (max-width: 767px) {
    .header__nav .block-menu {
      background: #808080;
      display: none;
      margin: 0 -0.75em; }
    .header__nav .menu {
      margin: 0; }
    .header__nav .menu__item {
      margin: 0; }
    .header__nav .menu__link {
      border-bottom: 1px solid #fff;
      color: #fff;
      display: block;
      padding: 0.75em; }
    .header__nav .menu .menu {
      background: #9a9a9a;
      border-bottom: 1px solid #fff; }
    .header__nav .nav-main__icon--open + .header__region .block-menu {
      display: block; }
    .header__nav .nav-main__icon {
      text-align: right; }
      .header__nav .nav-main__icon::after {
        background-image: url("../images/icon--menu.svg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        display: inline-block;
        content: '';
        height: 2.5em;
        width: 2.5em; } }
  @media (min-width: 768px) {
    .header__nav .menu {
      display: flex;
      justify-content: space-between;
      margin: 0;
      padding: 0;
      position: relative; }
    .header__nav .menu__item {
      border-right: 0.1em solid transparent;
      border-bottom: 0.1em solid transparent;
      flex-grow: 1;
      margin: 0; }
      .header__nav .menu__item:last-child {
        border-right: 0; }
    .section-wohnungssuche .header__nav .menu__item.wohnungssuche .menu {
      display: block; }
    .section-wohnungssuche .header__nav .menu:hover .menu__item.wohnungssuche .menu {
      display: none; }
    .section-wohnungssuche .header__nav .menu:hover .menu__item.wohnungssuche:hover .menu {
      display: block; }
    .header__nav .menu__link {
      background: rgba(0, 0, 0, 0.33);
      color: #fff;
      display: block;
      padding: 0.25em 1em;
      text-align: center;
      text-transform: uppercase; }
      .header__nav .menu__link.active, .header__nav .menu__link.is-active-trail, .header__nav .menu__link:hover {
        background: rgba(0, 0, 0, 0.66);
        text-decoration: none; }
      .header__nav .menu__link::after {
        content: ' »';
        padding-left: 0; }
    .header__nav .menu .menu {
      background: rgba(0, 0, 0, 0.66);
      column-count: 4;
      display: none;
      left: 0;
      padding: 1.5em;
      position: absolute;
      top: 100%;
      width: 100%; }
      .header__nav .menu .menu .menu__item {
        border: 0; }
      .header__nav .menu .menu .menu__link {
        background: transparent;
        text-align: left;
        text-transform: none;
        padding: 0; }
        .header__nav .menu .menu .menu__link.active, .header__nav .menu .menu .menu__link:hover {
          color: #D8232A;
          text-decoration: none; }
        .header__nav .menu .menu .menu__link::after {
          display: none; }
    .header__nav .menu__item:hover .menu {
      display: block; } }

.touch-device .first-tap + .menu {
  display: none; }

.touch-device .menu__link:not(.first-tap) + .menu {
  display: block; }

.nav__meta {
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  max-width: 1200px; }
  .nav__meta .menu {
    background: #F3F3F3;
    display: flex;
    justify-content: flex-end;
    margin: 0;
    padding: 0; }
  .nav__meta .menu__item {
    margin: 0; }
    .nav__meta .menu__item::before {
      content: '|'; }
    .nav__meta .menu__item:first-child::before {
      display: none; }
  .nav__meta .menu__link {
    display: inline-block;
    padding: 0.25em 0.375em; }
    @media (min-width: 768px) {
      .nav__meta .menu__link {
        font-size: 0.875em;
        padding: 0.25em 1.5em; } }
    .nav__meta .menu__link.active, .nav__meta .menu__link:hover {
      color: #D8232A;
      text-decoration: none; }
    .nav__meta .menu__link::after {
      content: ' »'; }

.node-mitarbeiter .field-name-field-vorname,
.node-mitarbeiter .field-name-field-nachname {
  display: inline-block;
  padding-right: 0.4em; }

.node-news.view-mode-teaser {
  cursor: pointer; }

@media (min-width: 768px) {
  .node-wohnung .field-name-field-ausstattung,
  .node-wohnung .field-name-field-besonderheiten {
    display: flex; } }

.node-wohnung .field-name-field-adresse .gmap {
  width: 100% !important; }

.node-wohnung .content__left .field {
  margin: 0.2em 0; }
  .node-wohnung .content__left .field p {
    margin: 0; }
  .node-wohnung .content__left .field ul {
    margin: 0; }

.node-wohnung .content__left .label-inline {
  display: block; }
  @media (min-width: 768px) {
    .node-wohnung .content__left .label-inline {
      display: inline-block;
      width: 40%;
      min-width: 210px; } }

.node-wohnung .content__right .field {
  margin-bottom: 1.5em; }

.node-wohnung .button {
  display: block;
  width: 100%;
  margin: 1.5em 0;
  text-align: center; }

.node-wohnung.view-mode-teaser {
  cursor: pointer; }
  .node-wohnung.view-mode-teaser .field-name-field-zimmer,
  .node-wohnung.view-mode-teaser .field-name-field-wohnflaeche,
  .node-wohnung.view-mode-teaser .field-name-field-etage {
    display: inline-block; }
    .node-wohnung.view-mode-teaser .field-name-field-zimmer + div::before,
    .node-wohnung.view-mode-teaser .field-name-field-wohnflaeche + div::before,
    .node-wohnung.view-mode-teaser .field-name-field-etage + div::before {
      content: ', '; }
  .node-wohnung.view-mode-teaser .field-name-field-gesamtmiete::before {
    display: none; }

.page-user .content {
  margin: 4em auto;
  max-width: 1200px; }

.page-user .tabs {
  display: flex;
  margin: 0 -1.5em; }
  .page-user .tabs li {
    margin: 0 1.5em; }

.view--layout-teaser .view-content {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.09375em; }
  @media (min-width: 768px) {
    .view--layout-teaser .view-content {
      margin: 0 -0.1875em; } }

.view--layout-teaser .views-row {
  display: flex;
  margin-bottom: 0.1875em;
  padding: 0 0.09375em; }
  @media (min-width: 768px) {
    .view--layout-teaser .views-row {
      margin-bottom: 0.375em;
      padding: 0 0.1875em;
      width: 50%; } }

.view .pager {
  display: flex;
  justify-content: center;
  margin: 1.5em 0 0; }
  .view .pager li {
    margin: 0; }

.view-mode-teaser {
  background: #F3F3F3;
  border: 0.1em solid #808080;
  padding: 0.75em;
  width: 100%; }
  @media (min-width: 768px) {
    .view-mode-teaser {
      padding: 1.5em; } }
  .view-mode-teaser h2 {
    line-height: 1.25;
    margin-bottom: 0.25em; }
  @media (min-width: 768px) {
    .view-mode-teaser .field-name-body {
      font-size: 0.875em; } }
  .view-mode-teaser .more-link {
    display: block;
    text-align: right; }
    .view-mode-teaser .more-link:after {
      content: ' »'; }
  .view-mode-teaser .label-inline {
    font-weight: 400; }

.webform-client-form {
  width: 100%; }

.webform-component label {
  display: block; }

.webform-component .form-type-radio label {
  display: inline; }
