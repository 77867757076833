.page-user {
  .content {
    margin: 4em auto;
    max-width: $page-max-width;
  }

  .tabs {
    display: flex;
    margin: 0 0 - $page-gutter;

    li {
      margin: 0 $page-gutter;
    }
  }
}
