.form-item {
  margin: 1em 0;
}

legend {
  font-weight: bold;
  text-transform: uppercase;
}

fieldset {
  border: 1px solid $color-grey-light;
  padding: $page-gutter / 2;

  @include breakpoint($breakpoint-narrow) {
    padding: $page-gutter;
  }
}

textarea,
input {
  border: 1px solid $color-grey-light;
  font-size: 1em;
  padding: 0.25em;
}

input[type="text"],
input[type="email"],
textarea {
  display: block;
  width: 75%;

  &:focus {
    outline: none;
  }

  &::-webkit-input-placeholder {
    color: $font-color;
  }

  &::-moz-placeholder {
    color: $font-color;
  }

  &:-ms-input-placeholder {
    color: $font-color;
  }

  &:-moz-placeholder {
    color: $font-color;
    font-size: px2em(20);
  }
}

input[type="submit"],
.buttons a {
  color: $color-white;
  cursor: pointer;
  background-color: $color-rot;
  border: 0;
  display: inline-block;
  padding: 0.25em 2em;
  margin: 0 0.5em;
  text-decoration: none;
  transition: background-color 0.5s linear;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background-color: darken($color-rot, 10%);
    text-decoration: none;
  }

}

label {
  display: block;
}

.label-inline {
  display: inline-block;
  font-weight: bold;
}
