//$base-font-size: 1.6em;
$base-line-height: 1.5;

$color-white: #fff;
$color-red: #D8232A;
$color-black: #000;

$color-status: #006400;
$color-error: #9d1309;
$color-warning: #b8860b;

$color-facebook: #3b5998;
$color-twitter: #1da1f2;
$color-whatsapp: #34af23;

$color-grey: #808080;
$color-grey-light: #F3F3F3;
$color-gelb: #F3E500;
$color-orange: #F18E1C;
$color-rot: #e30613;
$color-violett: #6D398B;
$color-blau: #0696BB;
$color-gruen: #008E5B;

$color-primary: hsl(0,0%,10%);
$color-secondary: hsl(0,0%,25%);
$color-tertiary: hsl(0,0%,40%);


$font-color: $color-primary;
$font-family: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans";
$font-icon: 'FontAwesome';
$font-line-height: 1.5;


$page-max-width: 1200px;
$content-max-width: $page-max-width;

$breakpoint-landscape: (orientation landscape);
$breakpoint-landscape-extrem: (orientation landscape) (min-aspect-ratio '2/1');
$breakpoint-narrow: 768px;
$breakpoint-wide: 1024px;
$breakpoint-menu: 1110px;

$page-gutter: 1.5em;
