.node-wohnung {

  .field-name-field-ausstattung,
  .field-name-field-besonderheiten {

    @include breakpoint($breakpoint-narrow) {
      display: flex;
    }
  }

  .field-name-field-adresse {
    .gmap {
      width: 100% !important;
    }
  }


  .content__left {

    .field {
      margin: 0.2em 0;

      p {
        margin: 0;
      }

      ul {
        margin: 0;
      }
    }

    .label-inline {
      display: block;

      @include breakpoint($breakpoint-narrow) {
        display: inline-block;
        width: 40%;
        min-width: 210px;
      }
    }
  }

  .content__right {
    .field {
      margin-bottom: $page-gutter;
    }
  }

  .button {
    display: block;
    width: 100%;
    margin: $page-gutter 0;
    text-align: center;
  }

  &.view-mode-teaser {
    cursor: pointer;

    .field-name-field-zimmer,
    .field-name-field-wohnflaeche,
    .field-name-field-etage {
      display: inline-block;

      & + div::before {
        content: ', ';
      }
    }

    .field-name-field-gesamtmiete {
      &::before {
        display: none;
      }
    }
  }
}
