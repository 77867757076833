.nav__meta {
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  max-width: $page-max-width;

  .menu {
    background: $color-grey-light;
    display: flex;
    justify-content: flex-end;
    margin: 0;
    padding: 0;

  }

  .menu__item {
    margin: 0;

    &::before {
      content: '|';
    }

    &:first-child::before
    {
      display: none;
    }
  }

  .menu__link {
    display: inline-block;
    padding: 0.25em $page-gutter / 4;

    @include breakpoint($breakpoint-narrow) {
      font-size: px2em(14);
      padding: 0.25em $page-gutter;
    }

    &.active,
    &:hover {
      color: $color-red;
      text-decoration: none;
    }

    &::after {
      content: ' »';
    }
  }
}
