$component: 'gallery';

.#{$component} {

  img {
    line-height: 0;
  }

  &__pager {
    display: flex;
    flex-wrap: wrap;
    margin: $page-gutter -0.2em;
  }

  &__pager-item {
    border: 0.2em solid $color-white;
    cursor: pointer;
    line-height: 0;
    width: 25%;
  }

  .cycle-pager-active {
    border: 0.2em solid $color-rot;
  }

  .field-item {
    line-height: 0;
  }
}
