.messages {

  padding: 0.5em 1em;
  background-color: $color-status;
  color: $color-white;
  margin-top: $page-gutter;
  width: 100%;

  a {
    color: $color-white;
    text-decoration: underline;
  }


  @include breakpoint($breakpoint-narrow) {
    padding: 1em 2em;
  }

  &.warning {
   background-color: $color-warning;
  }

  &.error {
    background-color: $color-error;
  }

  ul {
    list-style: none;
    max-width: $page-max-width;
    margin: 0 auto;

    li {
      padding: 0.25em 0;
    }
  }
}
