.view {

  $row_gutter: $page-gutter / 8;

  &--layout-teaser {

    .view-content {
      display: flex;
      flex-wrap: wrap;
      margin: 0 0 - $row_gutter / 2;

      @include breakpoint($breakpoint-narrow) {
        margin: 0 0 - $row_gutter;
      }
    }

    .views-row {
      display: flex;
      margin-bottom: $row_gutter;
      padding: 0 $row_gutter / 2;

      @include breakpoint($breakpoint-narrow) {
        margin-bottom: $row_gutter * 2;
        padding: 0 $row_gutter;
        width: 50%;
      }
    }
  }

  .pager {
    display: flex;
    justify-content: center;
    margin: $page-gutter 0 0;

    li {
      margin: 0;
    }
  }
}
