$component: 'compass';

.#{$component} {
  display: flex;
  justify-content: center;

  &__image {
    background-image: url('../images/wgab-kompass-nord.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 3em;
    width: 3em;

    &--nno {
      transform: rotate(22.5deg);
    }

    &--no {
      transform: rotate(45deg);
    }

    &--ono {
      transform: rotate(67.5deg);
    }

    &--o {
      transform: rotate(90deg);
    }

    &--oso {
      transform: rotate(112.5deg);
    }

    &--so {
      transform: rotate(135deg);
    }

    &--sso {
      transform: rotate(157.5deg);
    }

    &--s {
      transform: rotate(180deg);
    }

    &--ssw {
      transform: rotate(202.5deg);
    }

    &--sw {
      transform: rotate(225deg);
    }

    &--wsw {
      transform: rotate(247.5deg);
    }

    &--w {
      transform: rotate(270deg);
    }

    &--wnw {
      transform: rotate(292.5deg);
    }

    &--nw {
      transform: rotate(315deg);
    }

    &--mmw {
      transform: rotate(337.5deg);
    }
  }
}
