body {
  margin: 0;
  padding: 0;
}
.region-hidden {
  display: none;
}

.page {
  padding: 0 $page-gutter / 2;

  @include breakpoint($breakpoint-narrow) {
    padding: 0 $page-gutter;
  }
}
