$component: 'footer';

.#{$component} {
  background: $color-grey;
  color: $color-white;
  margin: $page-gutter 0 - $page-gutter/2 0;
  padding: $page-gutter/2;

  @include breakpoint($breakpoint-narrow) {
    margin: $page-gutter * 2 0 - $page-gutter 0;
    padding: $page-gutter;
  }

  a {
    color: $color-white;
  }

  &__inner {

    margin: 0 auto;
    max-width: $page-max-width;
  }

  .region-footer {
    margin: 0 0 - $page-gutter/2;

    @include breakpoint($breakpoint-narrow) {
      align-items: flex-end;
      display: flex;
      margin: 0 0 - $page-gutter;
    }
  }

  .block {
    margin: 0 $page-gutter / 2 2em;

    @include breakpoint($breakpoint-narrow) {
      margin: 0 $page-gutter;
      width: 50%;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
