$component: 'header';

.#{$component} {
  margin: 0 auto;
  max-width: $page-max-width;
  position: relative;
  z-index: 200;

  &__nav,
  .nav-filter {

    @include breakpoint($breakpoint-narrow) {
      left: 0;
      position: absolute;
      top: 100%;
    }
  }

  &__inner {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    padding: $page-gutter 0;

    @include breakpoint($breakpoint-narrow) {
      flex-wrap: wrap;
      padding: 0 0 $page-gutter;
    }
  }

  &__logo {
    width: 30%;

    @include breakpoint($breakpoint-narrow) {
      width: 15%;
    }

    img {
      height: 100%;
      width: 100%;
      max-width: 7.5rem;
    }
  }

  &__slogan {
    padding-left: $page-gutter;
    width: 70%;
    text-align: right;

    @include breakpoint($breakpoint-narrow) {
      width: 2 / 3 * 100%;
    }

    img {
      height: 100%;
      width: 100%;
      max-width: 40rem;
    }
  }
}
