@font-face {
  font-family: 'FontAwesome';
  src: url('../bower_components/font-awesome/fonts/fontawesome-webfont.eot?v=public/themes/base/bower_components/font-awesome/fonts');
  src: url('../bower_components/font-awesome/fonts/fontawesome-webfont.eot?#iefix&v=4.0') format('embedded-opentype'),
    url('../bower_components/font-awesome/fonts/fontawesome-webfont.woff2?v=4.0') format('woff2'),
    url('../bower_components/font-awesome/fonts/fontawesome-webfont.woff?v=4.0') format('woff'),
    url('../bower_components/font-awesome/fonts/fontawesome-webfont.ttf?v=4.0') format('truetype'),
    url('../bower_components/font-awesome/fonts/fontawesome-webfont.svg?v=4.0#fontawesomeregular') format('svg');
//  src: url('../bower_components/font-awesome/fonts/FontAwesome.otf') format('opentype'); // used when developing fonts
  font-weight: normal;
  font-style: normal;
}
