html {
  font-weight: 300;
  font-size: 14 / 16 * 1em;

  @include breakpoint($breakpoint-narrow) {
    font-size: 1em;
  }
}

body {
  color: $font-color;
  font-family: $font-family;
  line-height: $font-line-height;
  text-rendering: auto; // optimizelegibility throws things off #1094
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
%h1 {
  font-weight: bold;
  font-size: 1.5em;
  line-height: 1.2;
  margin: 1em 0;
  text-transform: uppercase;

  @include breakpoint($breakpoint-narrow) {
    font-size: 1.5em;
  }

  &:first-child {
    margin-top: 0;
  }
}

h2,
%h2 {
  font-weight: bold;
  font-size: 1.5em;
  text-transform: uppercase;

  @include breakpoint($breakpoint-narrow) {
    font-size: 1.5em;
  }

  &:first-child {
    margin-top: 0;
  }
}

h3,
%h3 {
  font-weight: 300;
  font-size: 1em;

  @include breakpoint($breakpoint-narrow) {
    font-size: 1em;
  }

  &:first-child {
    margin-top: 0;
  }
}

p,
%p {
  font-size: 1em;
}
