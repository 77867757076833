.nav-filter {

  padding: $page-gutter /2;
  width: 100%;
  z-index: 10;

  @include breakpoint($breakpoint-narrow) {
    column-count: auto !important;
    display: none;
    padding: 0;
  }

  .section-wohnungssuche & {
    display: block;
  }

  .region {
    color: $color-white;
    width: 100%;

    @include breakpoint($breakpoint-narrow) {
      display: flex;
      margin: 0 0 - $page-gutter;
    }
  }

  .block {
    flex-grow: 1;
    padding: $page-gutter / 2 0;

    @include breakpoint($breakpoint-narrow) {
      padding: 0 $page-gutter;
    }
  }

  h2 {
    font-size: 1em;
    margin: 0;

    @include breakpoint($breakpoint-narrow) {
      margin: 0 0 1em;
    }
  }

  a {
    color: $color-white;

    &:hover {
      color: $color-red;
      text-decoration: none;
    }
  }

  ul {
    margin: 0;
    padding: 0;
  }

  .leaf {
    margin: 0;
    padding: 0;
  }
}
