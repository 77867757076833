$component: 'content';

.#{$component} {

  ul {
    list-style: disc;
    padding-left: 1.2rem;
  }

  ol {
    list-style: decimal;
    padding-left: 1.2rem;
  }

  &__image {
    margin: 0 0 - $page-gutter / 2;

    @include breakpoint($breakpoint-narrow) {
      margin: 0 0 - $page-gutter;
    }

    img {
      max-width: none;
      width: 100%;
    }
  }

  &__inner {
    margin: $page-gutter auto 0;
    max-width: $page-max-width;

    @include breakpoint($breakpoint-narrow) {
        margin: 2rem auto 0;
    }

    .node-wohnung & {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__left {

    @include breakpoint($breakpoint-narrow) {
      padding-right: $page-gutter / 2;
      width: 60%;
    }
  }

  &__right {

    @include breakpoint($breakpoint-narrow) {
      padding-left: $page-gutter / 2;
      width: 40%;
    }
  }
}
