.field-name-field-bilder-inhalt {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.5em;

  .field-item {
    line-height: 0;
    padding: 0.5em;
    width: 20%;
  }
}
