.footer .menu {
  display: flex;
  margin: 0;
  padding: 0;

  .menu__item {
    margin: 0;

    &::after {
      content: '|';
      margin: 0 0.5em;
    }

    &:last-child::after {
      display: none;
    }
  }
}
