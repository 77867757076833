.content-bottom {
  margin: $page-gutter 0 0;
  position: relative;

  @include breakpoint($breakpoint-narrow) {
    margin: $page-gutter * 2 0 0;
  }

  &__inner {
    margin: $page-gutter auto 0;
    max-width: $page-max-width;

    @include breakpoint($breakpoint-narrow) {
      margin: $page-gutter * 2 auto 0;
    }
  }


  &:before {
    background: $color-rot;
    content: '';
    display: block;
    padding-top: 10%;
    margin: 0 0 - $page-gutter / 2;

    @include breakpoint($breakpoint-narrow) {
      margin: 0 0 - $page-gutter;
    }
  }
}
